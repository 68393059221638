// ----- autogen:polyfills:start  -----
//
// ----- autogen:polyfills:end  -----

import {
  uint8arrayFromString,
  uint8arrayToString,
  uint8ArrayToBase64,
} from './lib/uint8arrays';

export { uint8arrayFromString, uint8arrayToString, uint8ArrayToBase64 };
